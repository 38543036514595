import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/video-streaming-developer-docs/video-streaming-developer-docs/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Overview`}</h2>
    <p>{`The Organization API allows the management of various aspects of an Organization.`}</p>
    <p>{`Every API call need a token associated with a user with `}<strong parentName="p">{`Organization administrator`}</strong>{` access level.`}</p>
    <p>{`There are four groups of calls under the API:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/organization-api/organization-settings"
        }}>{`Organization Settings`}</a>{`: lists Organizations and allows the changing an Organization’s name`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/organization-api/sso-settings"
        }}>{`SSO Settings`}</a>{`: used to configure the account’s SSO login settings under the Organization. Currently two types of SSO providers are supported: Google Apps and SAML based`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/organization-api/administrators"
        }}>{`Administrators`}</a>{`: used to manage the Administrators and their Permissions`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/organization-api/custom-roles"
        }}>{`Custom Roles`}</a>{`: Other than the built-in roles like Organization Administrator and Channel Administrator you can create Custom Roles and associate permissions to them`}</li>
    </ul>
    <h2>{`How Organizations, accounts and Channels relate to each other`}</h2>
    <p>{`On IBM Video Streaming a `}<strong parentName="p">{`Channel`}</strong>{` is a container for videos. You can upload videos to a Channel or start a broadcast on it.`}</p>
    <p>{`An `}<strong parentName="p">{`Account`}</strong>{` is a set of Channels, and it is associated with a Subscription which makes it easier to measure usages of IBM Video Streaming’s resources.`}</p>
    <p>{`An `}<strong parentName="p">{`Organization`}</strong>{` is basically a set of Accounts which makes it easier for a larger company or organization with multiple Accounts to manage users and their permissions.`}</p>
    <h2>{`Permissions`}</h2>
    <p>{`On IBM Video Streaming there are a couple of predefined Roles.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`NAME`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`DESCRIPTION`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Organization Administrator`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Can manage settings and content of an Organization (SSO Settings, administrators, accounts, Channels and videos). You can think an Organization administrator as a “Superuser” in the Organization`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Account Administrator`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Can manage every settings and content of an account and the Channels and Videos under it`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Channel Administrator`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Can manage every settings and content of a Channel and the videos under it`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`Other than the predefined Roles you can create your own to fine tune the Permissions of Administrators in your Organization. Make sure you read the `}<a parentName="p" {...{
        "href": "/organization-api/custom-roles#create-a-custom-role"
      }}>{`Create a custom role`}</a>{` page for further details.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      